var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m-5"},[_c('div',{staticClass:"text-right"},[_c('b-dropdown',{ref:"dropdown",staticClass:"no-btn",attrs:{"id":"dropdown-form","variant":"link","right":"","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [(_vm.photo)?_c('div',{staticClass:"wh-40-img rounded bg-cover-center",style:({
                    'background-image':
                     'url(' + _vm.photo + ')',
                })}):_c('div',{staticClass:"wh-40-img rounded bg-cover-center",style:({
                    'background-image':
                        'url(' +
                        require('@/assets/img/no-photo-2.jpg') +
                        ')',
                })})]},proxy:true}])},[_c('b-dropdown-item',{on:{"click":_vm.logoutStudent}},[_vm._v("Logout")])],1)],1),_c('datatable',{directives:[{name:"show",rawName:"v-show",value:(_vm.datatable.showTable),expression:"datatable.showTable"}],attrs:{"isLoading":_vm.datatable.isLoading,"columns":_vm.datatable.columns,"rows":_vm.datatable.rows,"total":_vm.datatable.total,"queryParams":_vm.datatable.queryParams},on:{"update:isLoading":function($event){return _vm.$set(_vm.datatable, "isLoading", $event)},"update:is-loading":function($event){return _vm.$set(_vm.datatable, "isLoading", $event)},"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-per-page-change":_vm.onPerPageChange}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }